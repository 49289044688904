<template>
  <div class="container">
    <v-progress-circular
      :size="70"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <div class="message-text">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { delay } from "@/utilities/delay.js";
import { mapActions } from "vuex";

export default {
  mounted() {
    this.calculateCarbon();
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    ...mapActions(["calculateCarbonEmission"]),

    async calculateCarbon() {
      this.message = "Calculating your emissions...";
      await delay(2000);
      this.message = "Building your offset profile...";
      // send calculation request
      await this.calculateCarbonEmission();
      await delay(2000);
      await this.$router.push({
        name: "ChoosePlan",
        replace: true,
      });
    },
  },
};
</script>

<style scoped>
.container {
  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  font-family: "League Spartan", sans-serif;
}
.message-text {
  margin-top: 20px;
}
</style>